import React, { useEffect, useState } from 'react';
import { FaRocket } from 'react-icons/fa';
import './Projects.css';

function Projects() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch('/projects.json')
      .then(response => response.json())
      .then(data => setProjects(data));
  }, []);

  return (
    <div className="projects-container">
      <center><h2 className="title">Our Projects</h2></center>
      <div className="project-list">
        {projects.map((project, index) => (
          <div key={index} className="project-item">
            <FaRocket className="project-icon" />
            <h3 className="project-name">{project.name}</h3>
            <p className="project-domain"><strong>Domain:</strong> {project.domain}</p>
            <p className="project-description">{project.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
