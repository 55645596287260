import React from 'react';
import { FaCheckCircle, FaPhone, FaEnvelope } from 'react-icons/fa';
import './Home.css'; // Make sure to create this CSS file

function Home() {
  return (
    <div className="home-container">
      <header className="home-header">
        <h2>Welcome to CodeViaje!</h2>
        <p>
          At CodeViaje, we specialize in providing innovative projects across the latest IT domains, catering to both mini and major project needs.
        </p>
      </header>

      <section className="services-section">
        <h3>Our Services</h3>
        <ul className="services-list">
          <li><FaCheckCircle className="icon" /><em>Project Samples</em>: Explore a variety of sample projects in current IT domains.</li>
          <li><FaCheckCircle className="icon" /><em>Customization</em>: Projects can be fully customized to meet your specific requirements.</li>
          <li><FaCheckCircle className="icon" /><em>Complete Assistance</em>: We provide end-to-end support, from project inception to completion.</li>
          <li><FaCheckCircle className="icon" /><em>Research and Report Writing</em>: Full support for research papers and report writing is available.</li>
          <li><FaCheckCircle className="icon" /><em>Final Year Projects</em>: Dedicated guidance to help you choose and execute your project wisely.</li>
        </ul>
      </section>

      <section className="why-choose">
        <h3>Why Choose CodeViaje?</h3>
        <ul className="benefits-list">
          <li><FaCheckCircle className="icon" /><em>Expert Guidance</em>: Benefit from the expertise of our knowledgeable team at every stage of your project.</li>
          <li><FaCheckCircle className="icon" /><em>Latest Technologies</em>: Engage with projects that utilize the most current trends and technologies in IT.</li>
          <li><FaCheckCircle className="icon" /><em>Comprehensive Support</em>: Receive complete assistance, including detailed reports and research paper support.</li>
          <li><FaCheckCircle className="icon" /><em>Custom Solutions</em>: Tailor your project to your specific needs and demands.</li>
        </ul>
      </section>

      <footer className="contact-footer">
        <h3>Contact Us</h3>
        <p>
          <FaPhone className="icon" /> +91 7019194530
          <br />
          <FaEnvelope className="icon" /> codeviaje@gmail.com / support@codeviaje.in
        </p>
        <p>
          At CodeViaje, your project's success is our priority. Contact us today to explore our project options and begin your journey towards achieving your academic and professional goals.
        </p>
      </footer>
    </div>
  );
}

export default Home;
