import React from 'react';
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import './Contact.css'; // Make sure to create this CSS file

function Contact() {
  return (
    <div className="contact-container">
      <center>
        <h2>Contact Us</h2>
        <div className="contact-info">
          <p><FaEnvelope className="icon" /> codeviaje@gmail.com</p>
          <p><FaEnvelope className="icon" /> support@codeviaje.in</p>
          <p><FaPhone className="icon" /> +91 7019194530</p>
        </div>
      </center>
    </div>
  );
}

export default Contact;
