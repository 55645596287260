import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Make sure to create this CSS file

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo-container">
          <img src="/favicon.ico" alt="CodeViaje Logo" className="logo" />
          <h1 className="logo-text">CODEVIAJE</h1>
        </div>
        <nav className={`nav ${menuOpen ? 'nav-open' : ''}`}>
          <Link to="/" className="nav-link" onClick={() => setMenuOpen(false)}>Home</Link>
          <Link to="/Projects" className="nav-link" onClick={() => setMenuOpen(false)}>Projects</Link>
          <Link to="/Contact" className="nav-link" onClick={() => setMenuOpen(false)}>Contact Us</Link>
        </nav>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className={`bar ${menuOpen ? 'bar1' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'bar2' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'bar3' : ''}`}></div>
        </div>
      </div>
    </header>
  );
}

export default Header;
